@import "../../../../assets/scss/_var.scss";

.allCollection {
  .dropdown-menu {
    font-size: 12px;
    background: linear-gradient(#ffffff, #ffffff) padding-box,
      linear-gradient(120deg, rgba(92, 86, 242, 0.5), rgba(130, 29, 180, 0.5)) border-box;
    border: solid 1px transparent;

    a {
      color: #666;
    }
  }

  .banner_row {
    margin-top: 89px;

    >div {
      padding: 0 0.3rem;
    }

    .tab-content {
      padding-left: 0;

      .ContMain.container {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.collection_col {
  padding-top:50px;

  @media (max-width: 767px) {
    padding-top: 10px;
  }

  .main-heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    letter-spacing: 0.125em;

    color: $purpletext;
    margin-bottom: 6px;
    position: relative;

    @media (max-width: 991px) {
      font-size: 18px;
      line-height: 40px;
    }

    // &:before {
    //   content: " ";
    //   background: #0c9eda;
    //   width: 70px;
    //   height: 1px;
    //   position: absolute;
    //   bottom: 3px;
    // }
  }

  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 132.1%;
    letter-spacing: 0.1em;
    color: #9a9a9a;
    margin-bottom: 10px;

    @media (max-width: 991px) {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
}

.banner_btn {
  margin-top: 7%;

  button {
    padding: 12px 33px;
    font-size: 16px;
    font-weight: 400;
    margin-right: 10px;
  }
}

.col-new {
  border: 1px solid #45b3d2;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  height: 100%;
  position: relative;
  min-height: 200px;
  padding-bottom: 20px;
  padding-top: 20px;
  max-width: 226px;

  @media (max-width: 991px) {
    height: auto;
    margin-bottom: 10px;
  }

  h3 {
    font-size: 18px;
    color: $black;
    line-height: 22px;
    margin-bottom: 0;

    @media (max-width: 1199px) {
      font-size: 18px;
      line-height: 21px;
    }
  }

  a.create-btn {
    background: $btnGradient;
    border-radius: 30px;
    font-size: 14px;
    line-height: 19px;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 129px;
    transition: all 0.5s ease-in-out;
    border: none;
    margin: 25px 0;
    box-shadow: none !important;

    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 19px;
      height: auto;
      padding: 8px 17px;
    }

    &:hover {
      background: $baseColor;
    }

    img {
      margin-left: 20px;
    }
  }
}

.col-thumb {
  border-radius: 15px;

  h3 {
    font-size: 24px;
    color: $black;
    line-height: 29px;
    text-align: center;
    margin-top: 10px;

    @media (max-width: 767px) {
      font-size: 19px;
      line-height: 21px;
    }
  }

  img {
    width: 100%;
    border-radius: 15px;
    height: 253px;
    object-fit: cover;
    object-position: center;
  }
}

.white-bg {
  background-color: $white;
  padding: 40px;
  margin: 0 0 2px 2px;

  @media (max-width: 1199px) {
    padding: 20px;
  }
}

//   .item-col {
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

.collection {
  margin-top: 150px;
  background: $white;
  padding-top: 93px;
  padding-bottom: 100px;

  @media (max-width: 991px) {
    margin-top: 10px;
    padding-top: 15px;
    padding-bottom: 20px;
  }

  .main-heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.125em;
    color: #00c0d0;
    margin-bottom: 6px;

    @media (max-width: 767px) {
      font-size: 16px;

      line-height: 22px;
      margin-bottom: 10px;
    }
  }

  .coll-box {
    // display: flex;
    width: 100%;
    margin-left: 20px;
    // justify-content: start;
    align-items: center;

    @media (max-width: 767px) {
      display: block;
    }
  }
}

.business-list {
  position: relative;
  transition: all 0.3s;
  cursor: pointer;
  background-color: $white;
  padding: 40px;
  margin: 0 0 2px 2px;
  height: 100%;

  @media (max-width: 1199px) {
    padding: 20px;
  }

  .favorite {
    display: flex;
    justify-content: flex-end;
    margin-top: -10px;
    margin-right: -5px;
    font-size: 15px;
    font-weight: 400;

    img {
      margin-right: 5px;
    }
  }

  &:hover .overlay {
    opacity: 1;
  }

  .overlay {
    position: absolute;
    top: 40%;
    height: 222px;
    left: 50%;
    width: 100%;
    padding: 0 15px;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    transform: translate(-50%, -50%);

    &:before {
      background: rgba(255, 255, 255, 0.7);
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      filter: blur(5px);
      left: 0;
      content: " ";
    }

    .overlay-btns {
      position: relative;
      top: 40%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      .btn {
        font-size: 14px;
        line-height: 17px;
        color: $white;
        text-transform: uppercase;
        border-radius: 30px;
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 150px;
        align-items: center;
        width: 100%;

        @media (max-width: 1199px) {
          font-size: 11px;
          line-height: 17px;
          height: 35px;
          max-width: 120px;
        }
      }

      .buy-btn {
        background: $baseColor;
        border: none;

        &:hover {
          background: transparent;
          color: $baseColor;
          border: 1px solid $baseColor;
        }
      }

      .read-btn {
        background: $btnlight;
        border: none;
        margin-bottom: 20px;

        &:hover {
          background: transparent;
          color: $btnlight;
          border: 1px solid $btnlight;
        }
      }
    }

    .read-btn {
      background: $baseColor;
      border: none;
      margin-bottom: 20px;
    }
  }
}

.business-info {
  text-align: center;
  justify-content: space-between;
  margin-top: 30px;

  h3 {
    font-size: 14px;
    color: #6571e1;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 0;
  }

  p {
    font-size: 16px;
    color: $black;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  .price {
    text-align: center;

    img {
      width: 12px;
      height: 20px;
      margin-right: 8px;
    }
  }
}

.business-img {
  margin-bottom: 25px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  img.list-img {
    height: 220px;
    width: 100%;
    object-fit: contain;
    transition: all 0.3s ease;
  }
}

button.create-btn {
  min-height: 40px;
  background: $btnGradient;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  padding: 0 29px;
  line-height: 50px;
  // @include transition(all ease 0.4s);
  // &:hover {
  //   box-shadow: 4px 4px 7px #00000030;
  //  @include transition(all ease 0.4s);
  // }
}

.toggle {
  margin-left: 0;
  position: absolute;
  right: 20px;
  top: 20px;
  width: 4px;
  height: 18px;
}

.coll-new {
  text-align: center;
  word-break: break-all;
  justify-content: flex-start;
  overflow: hidden;
  // width: 300px;
  // height: 300px;

  img {
    border-radius: 3px;
  }

  p {
    color: $black;
    font-size: 15px;
    line-height: 24px;
    margin-top: 10px;
    padding: 0 26px;
  }
}