.pagination {
  display: flex;
  padding-left: 0;
  justify-content: space-between;
  list-style: none;
}

ul.pagination li {
  background: grey;
  padding: 10px 18px;
  border-radius: 50%;
}

ul.pagination li a {
  color: #fff;
  text-decoration: none;
}

.title {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 24px;
  line-height: 132.1%;
  margin-bottom: 12px;
  letter-spacing: 0.02em;
  color: #5c56f2;
}

.title.black {
  /* color:black!important; */
  background: linear-gradient(to right, #5c56f2, #821db4);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

@media (max-width: 1080px) {
  .title {
    font-size: 26px;
  }
}

.nft_head {
  margin: 0 auto;
  max-width: 450px;
}

.nft_head_margin {
  margin: unset;
}

.nft_title {
  color: gray;
  font-size: 16px;
}

.banner_row>div {
  padding: 0 0.3rem;
}