@import "../../../assets/scss/_var.scss";
@import "../../../assets/scss/_mixins.scss";

.nftAttributes.col {
    // padding-left: 5%;
    ul {
        padding-left: 0;
        li {
            padding-left: 20px;
            position: relative;
            margin-bottom: 10px;
            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                width: 6px;
                height: 6px;
                background: #333;
                border-radius: 100px;
                transform: translateY(-50%);
            }
            h4 {
                margin-bottom: 0;
                text-transform: capitalize;
                color: #333;
                @include fluid-type(320px, 1920px, 18px, 20px);
                span {
                    color: #666;
                    @include fluid-type(320px, 1920px, 16px, 18px);

                }
            }
        }
    }
}