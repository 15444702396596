.cardBlocks {
  padding: 20px 0;

  .row {
    // justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 31px;

  }

  .card_link {
    cursor: pointer;
  }

  .cards {
    height: 100%;
    position: relative;
    flex: 0 1 24%;
    border-radius: 10px;
    border: none;
    margin-bottom: 20px;

    @media (max-width: 767px) {
      max-width: 300px;
      width: 100%;
      margin: 10px auto;
    }

    @media (max-width: 767px) {
      max-width: 100%;
    }

    .card-img {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .mainImg {
      width: 100%;
      height: 269px;

      @media (max-width: 767px) {
        height: auto;
      }
    }

    .content {
      display: flex;
      align-items: top;
      position: relative;
      justify-content: space-between;
      // position: absolute; /* Position the background text */
      bottom: 0;
      /* At the bottom. Use top:0 to append it to the top */
      width: 100%;
      /* Full width */
      padding: 20px 20px 3px;
      background: #ffffff;
      box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.25);
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;

      .coin_image {
        width: 50px;
        height: 36px;
        object-fit: contain;
        position: absolute;
        right: 0;
        top: 14px;

        img {
          width: 36px;
          height: auto;
        }
      }

      .card-title {
        font-weight: 700;
        font-size: 15px;
        line-height: 15px;
        letter-spacing: 0.07em;
        color: #7c7c7c;
        max-width: 100%;

        @media (max-width: 480px) {
          font-size: 12px;
          letter-spacing: 0.01em;
        }
      }

      .card-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.05em;
        color: #969694;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;

        // margin-top: 15px;
        @media (max-width: 480px) {
          letter-spacing: 0.01em;
        }
      }
    }
  }
}