@import "../../../assets/scss/_var.scss";
@import "../../../assets/scss/_mixins.scss";

.header {
  padding: 6px 50px;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
  // background: $white;
  background: linear-gradient(90deg, #5C56F2 0%, #821DB4 72.47%);

  @include breakpoint(medium) {
    padding: 0 25px;
  }

  .navbar {
    padding: 2.5px 0;

    .navbar-collapse {
      justify-content: flex-end;

      @include breakpoint(small) {
        margin-top: 15px;
        // background: #efefef;
        // background: transparent;
        background: transparent;
        border: 1px solid $white;
        padding: 20px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        @media(max-width: 767px) {
          padding: 10px;
        }

        .cmnBtn {
          text-align: center;
        }

        .media {
          justify-content: center;

          @media (max-width: 567px) {
            margin-top: 10px;
          }
        }
      }
    }

    .navbar-brand {
      img {
        max-width: 121px;
        height: 34px;

        @media (max-width: 576px) {
          max-width: 150px;
          height: 50px;
        }

        @media (max-width: 320px) {
          max-width: 100px;
          height: 30px;
        }
      }
    }

    .navbar-toggler {
      &:focus {
        box-shadow: unset;
      }
    }
  }

  // ..................../
  .navbar-nav {
    align-items: center;
    gap: 10px;

    a {
      text-decoration: none;
      color: white;
      padding: 12px 25px;
      margin-right: 25px;
      background: transparent linear-gradient(90deg,
          #44dfd1 1%,
          #44d3d1 20%,
          #45b4d2 54%,
          #4682d3 99%,
          #4782d4 100%) 0% 0% no-repeat padding-box;
      box-shadow: 4px 4px 7px #00000030;
      border-radius: 22px;

      @include breakpoint(small) {
        margin-right: 0;
        margin-bottom: 15px !important;
      }

      @media (max-width: 576px) {
        padding: 10px 20px;
      }
    }

    .cmnBtn {
      margin-right: 15px;
      height: 23px;
      font-style: normal;
      font-weight: 700;
      font-size: 9px;
      line-height: 132.1%;
      letter-spacing: 0.46em;
      color: #ffffff;
      background: transparent;

      @media (max-width: 767px) {
        margin-right: 0;
        // margin-bottom: 8px;
      }
    }

    // >button {
    //   // border-bottom: 1px solid $white;
    // }
  }
}

.gearicon {
  position: relative;
  top: 1px;
  cursor: pointer;
  width: 23px;

  @media (max-width: 767px) {
    top: -10px;
  }
}

.loginBtn {
  color: $purpleclr !important;
  background-color: $white !important;
}