@import "../../../assets/scss/_var.scss";

.slider-sec {
  width: 100%;
  max-width: 375px;
  margin-left: auto;

  @media (max-width: 1366px) {
    transform: translate(0);
  }

  @media (max-width: 575px) {
    width: unset;
  }

  @media (max-width: 991px) {
    margin: 20px auto 0;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    display: none;
  }

  .carousel-control {
    border-radius: 10px;
  }

  .carousel-indicators li {
    border: solid 1px $purpleclr;
  }

  .carousel-indicators [data-bs-target] {
    height: 15px;
    width: 15px;
  }

  .carousel-indicators .active {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: solid 1px $purpleclr;
    background: linear-gradient(90deg, #5c56f2 0%, #821db4 72.47%) !important;
  }

  .carousel-indicators {
    margin: 0;
    max-width: 375px;
    bottom: -36px;

    button {
      width: 17px;
      height: 17px;
      border-radius: 50%;
      border: solid 2px #6843de;
      background: transparent !important;
      margin: 6px;
    }
  }

  .carousel-caption {
    min-height: 120px;
    text-align: start;
    width: 100%;
    left: 0;
    bottom: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 15px 18px;
    background: linear-gradient(90deg, #5a54ea 0%, #01a9f2 72.47%);

    .discription {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 109.9%;
      letter-spacing: -0.005em;
    }

    .concoin,
    a {
      color: $white;
      font-weight: 500;
      font-size: 16px;
      line-height: 124.9%;
      letter-spacing: -0.005em;
      text-decoration: none;
      margin-top: 8px;
    }
  }

  .imgWrap {
    // background: linear-gradient(90deg, #5a54ea 0%, #01a9f2 72.47%);
    border-radius: 20px;
    min-height: 375px;
    max-width: 375px;
    width: 100%;
    height: 100%;


    .astro {
      width: 100%;
      object-fit: cover;

      @media (max-width: 479px) {
        object-fit: unset;
      }
    }
  }

  .carousel-item {
    // max-width: 469px;
    max-width: 375px;
    width: 100%;
    overflow: hidden;
    border-radius: 15px;
    min-height: 375px;
    // background: linear-gradient(90deg, #5a54ea 0%, #01a9f2 72.47%);
  }
}