@import "../../../assets/scss/_var.scss";

.connect_wallet {
  .modal-dialog {
    display: flex;
    flex: 1;
    height: auto;
    .modal-body {
      display: flex;
      flex-direction: column;
    }
    .modal-content {
      // background: #40BBFB;
      // color: $white;
      border-radius: 10px;
      .modal-header {
        .modal-title {
          font-size: 18px;
          font-weight: 500;
        }
        .btn-close {
         
          outline: none;
        }
      }
    }
  }
}

.baseToken_style {
  ul {
    li {
      display: block;
      margin-right: 10px;
      margin-bottom: 10px;
      button {
        padding: 15px 20px;
        border: 1px solid $purpleclr!important;
        border-radius: 10px;
        text-decoration: none;
        color: #878787;
        display: block;
        background: $white;
        position: relative;
        width: 100%;
        text-align: left;
        border: 0;
        img {
          width: 27px;
          position: absolute;
          right: 15px;
          top: 16px;
          height: 27px;
        }
      }
    }
  }
  .add_new {
    a {
      text-decoration: none;
    }
  }
}

.walletBtns:disabled {
  pointer-events: none;
  background:#ddd
}
