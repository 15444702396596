@import "../../../assets/scss/_var.scss";

.creator_btn {
  &:hover {
    background: $white !important;
    color: $purpletext !important;
    border: 1px solid $purpleclr !important;
  }
  &:disabled {
    opacity: 0.6!important;
  }
}
