@import "../../../assets/scss/_var.scss";

.tooltip > div.tooltip-inner {
    background-color: #000 !important;
    color: #fff !important;
    padding: 10px;
    max-width: 300px;
  }

.tooltip_img {
  max-width:50px;
  height:auto;
}  