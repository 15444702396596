/* AudioPlayer.css */

.audio-player {
    text-align: center;
  }
  
  .audio-controls {
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    position: relative;

    button i {
        padding: 20px;
        border-radius: 50%;
        background: #821DB4;
    }

    .name-pos {
        position: absolute;
        top:75%;
        color:#fff;
        display:flex;
        justify-content: center;
        background-color: #000;
        padding:5px;
    }
  }
  
  .play-pause-button {
    font-size: 24px;
    background-color: transparent;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .play-pause-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  .playing .play-pause-button {
    background-color: transparent;
  }