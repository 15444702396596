.offcanvas {
  .subTitle {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.125em;
    color: #5C56F2;
    margin-bottom: 6px;
  }

  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 132.1%;
    letter-spacing: 0.045em;
    color: #898989;
    margin-bottom: 30px;
  }

  ul {
    padding-left: 0;
  }

  section {
    margin: 30px 0;
  }

  .first {
    ul {
      li {
        margin-top: 20px;

        h6 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 132.1%;
          letter-spacing: 0.115em;
          color: #4f4f4f;
          margin-bottom: 5px;
        }

        .dimond {
          transform: rotate(45deg);
          margin-right: 20px;
        }
      }
    }

    #head {
      border: 1px solid #0000;
      height: 28px;
      padding: 2px;
      background: linear-gradient(#fff, #fff) padding-box,
        linear-gradient(#5C56F2, #6150EC) border-box;
      // border-radius: 5px;
    }
  }

  .third {
    li {
      .box {
        border: 1px solid #0000;
        background: linear-gradient(#fff, #fff) padding-box,
          linear-gradient(#5C56F2, #6150EC) border-box;
      }
    }
  }

  .sidebarInputs {
    &::placeholder {
      font-weight: 500;
      font-size: 13px;
      letter-spacing: -0.005em;
      color: #c1c1c1;
    }
  }

  .labeldesign {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 132.1%;
    letter-spacing: 0.01em;
    color: #686868;
  }

  .form-group {
    margin-bottom: 20px;
  }

  .checkBox {
    display: none;

    &:checked+label::after {
      border: 1px solid #0000;
      background: linear-gradient(#5C56F2, #6150EC) padding-box,
        linear-gradient(#fff, #fff) border-box;
      width: 15px;
      height: 15px;
    }

    &+label {
      z-index: 3;
      cursor: pointer;
      padding-left: 33px;
      display: block;
      position: relative;
      height: 20px;
      line-height: 18px;
      color: #686868;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      letter-spacing: 0.03em;

      &::before {
        z-index: 2;
        top: 0px;
        left: 2px;
        width: 17px;
        height: 17px;
        background: linear-gradient(90deg, #5C56F2 0%, #6150EC 100%);
        display: block;
        content: "";
        position: absolute;
        // border-radius: 4px;
      }

      &::after {
        z-index: 2;
        top: 1px;
        left: 3px;
        width: 15px;
        height: 15px;
        background: #fff;
        display: block;
        content: "";
        position: absolute;
      }
    }
  }
}