@import "../../../../assets/scss/_var.scss";
@import "../../../../assets/scss/_mixins.scss";

// .CollapseBg {
//   .accordion-button {
//     background-color: transparent;
//     font-weight: 500;
//     font-size: 18px !important;
//     line-height: 22px;
//     letter-spacing: 0.02em;
//     color: #454545;
//     font-family: "Montserrat", sans-serif;
//     @media (max-width: 480px) {
//       font-size: 14px !important;
//     }
//     &::after {
//       background-image: url("../../../../assets/images/accodianArrow.svg");
//     }
//     &:focus {
//       box-shadow: unset;
//     }
//   }
//   .accordion-body {
//     @media (max-width: 480px) {
//       padding-top: 0;
//       padding-bottom: 0;
//     }

//     p {
//       @media (max-width: 480px) {
//         font-size: 12px;
//       }
//     }
//   }
//   .accordion-button:not(.collapsed) {
//     box-shadow: none;
//   }
//   .accordion-item {
//     background-color: transparent;
//     border: none;
//     border-bottom: solid 1px;
//     border-image: linear-gradient(45deg, #5a54ea, #01a9f2) 1;
//     padding: 22px 0;
//     @media (max-width: 567px) {
//       padding: 10px 0;
//     }
//     &:first-child {
//       border-top: solid 1px;
//       border-image: linear-gradient(45deg, #5a54ea, #01a9f2) 1;
//     }
//   }
//   .fQ {
//     ul.pagination {
//       margin-left: auto;
//     }
//   }
// }
.maintitle {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 39px;
  letter-spacing: -0.05em;
  color: $lightpurple;
  margin-bottom: 18px;
  font-family: "Montserrat", sans-serif;

  @media (max-width: 992px) {
    font-size: 28px;
    margin-bottom: 0px !important;
  }

  @media (max-width: 567px) {
    font-size: 18px;
  }
}

.arrow {
  // filter: invert(1);
  position: absolute;
  top: 80%;
  z-index: 1;
  cursor: pointer;

  @media(max-width: 991px) {
    top: 75%;
  }

  @media(max-width: 767px) {
    top: 60%;
  }
}

.arrowPrev {
  right: 40px;
  left: auto;

  @media(max-width: 767px) {
    img {
      width: 40px;

      @media(max-width: 479px) {
        width: 30px;
      }
    }
  }
}

.arrowNext {
  right: 100px;
  left: auto;

  @media(max-width: 767px) {
    right: 80px;
  }

  @media(max-width: 767px) {
    img {
      width: 40px;

      @media(max-width: 479px) {
        width: 30px;
      }
    }
  }
}

.slider_slick {
  margin-bottom: 150px;

  @media(max-width: 1199px) {
    margin-bottom: 100px;
  }

  @media(max-width: 767px) {
    margin-bottom: 50px;
  }

  .slick_slide {
    position: relative;

    h2 {
      font-size: 33px;

      @media(max-width: 767px) {
        font-size: 20px;
      }
    }

    .slide_content {
      position: absolute;
      top: 50%;
      color: $white;
      flex-direction: column;
      left: 70px;

      @media(max-width: 767px) {
        top: 20%;
        left: 5%;
      }
    }

    .read_btn {
      cursor: pointer;
      background: transparent;
      border: 1px solid $white;
      padding: 4px 0;
      font-size: 9px;
      border-radius: 4px;
      text-transform: uppercase;
      letter-spacing: 1px;
      min-width: 100px;
      width: 100px;
      padding: 6px 10px;
      justify-content: center;
      margin-top: 14px;

      &:focus {
        box-shadow: unset;
      }

      svg {
        display: none;
      }
    }

    img {
      width: 100%;
      border-radius: 14px;
    }
  }
}