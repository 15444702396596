@import "../../../../assets/scss/_var.scss";

.landing-page {
  // background-image: url("../../../../assets/images/landingBg.png");
  background-repeat: no-repeat;

  .titleSec {

    h3 {
      @media (max-width: 991px) {
        font-size: 16px;
        line-height: 1;
      }

      @media (max-width: 767px) {
        font-size: 14px;

      }
    }
  }

  .launchpad-Sec {
    // align-items: center;
    justify-content: space-between;
    margin-top: 160px;

    @media(max-width:991px) {
      margin-top: 60px;
    }

    @media(max-width:567px) {
      margin-top: 20px;
    }

    .maintext {
      background: linear-gradient(to right, #5C56F2, #821DB4);
      -webkit-text-fill-color: transparent;
      background-clip: text;
      // color: $heading;
      font-weight: 800;
      font-size: 48px;
      line-height: 59px;
      letter-spacing: -0.06em;
      font-family: "Montserrat";

      @media (max-width: 1200px) {
        font-size: 38px;
      }

      @media (max-width: 991px) {
        font-size: 28px;
      }

      @media (max-width: 480px) {
        font-size: 18px;
      }
    }

    .mb24 {
      margin-bottom: 24px;

      @media (max-width: 991px) {
        margin-bottom: 0px;
      }
    }

    .gline {
      border-top: solid 2px;
      border-image: linear-gradient(45deg, #5C56F2, #821DB4) 1;
      margin-top: 10px;
    }

    .subtext {
      font-weight: 600;
      font-size: 46px;
      line-height: 56px;
      letter-spacing: -0.05em;
      color: $greyclr;
      font-family: "Montserrat";

      @media (max-width: 1200px) {
        font-size: 36px;
        line-height: 26px;
      }

      @media (max-width: 991px) {
        font-size: 26px;
        line-height: 16px;
      }

      @media (max-width: 480px) {
        font-size: 16px;
      }
    }

    p {
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: -0.01em;
      color: $textColorNew;
      font-family: "Montserrat";

      @media (max-width: 1200px) {
        font-size: 16px;
      }

      &:first-child {
        margin-top: 22px;
      }
    }
  }

  .cardSec {
    margin: 142px 0 120px 0;

    @media (max-width: 767px) {
      margin: 70px 0 60px 0;
    }
  }

  .accordianBg {
    // background: url("../../../../assets/images/AccodianBg.png") no-repeat;
    padding-bottom: 135px;
    width: 100%;
    background-position: center;
    background-size: cover;

    @media (max-width: 767px) {
      padding-bottom: 60px;
    }
  }


}

.slick-dots {
  bottom: -75px;

  @media (max-width:767px) {
    bottom: -45px;

  }

  li {
    &.slick-active {
      button {
        width: 17px;
        height: 17px;
        border-radius: 50%;
        padding: 5px;
        background-color: $purpleclr;

      }
    }

    button {
      width: 17px;
      height: 17px;
      border: 1px solid $purpleclr;
      border-radius: 50%;

      &:before {
        content: unset;
      }
    }
  }
}