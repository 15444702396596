.max_wallet_per_mint {
  font-size: 15px !important;
  white-space: normal;
}

.increase-decrease-count {
  text-align: center;

  .labeltext {
    margin-bottom: 10px;
  }

  Form {
    width: 100%;

    .input-group {
      max-width: 140px;
      border: 1px solid #0000;
      border-radius: 10px;
      height: 35px;
      background: linear-gradient(#ffffff, #ffffff) padding-box,
        linear-gradient(120deg, #5C56F2, #6150EC) border-box;

      .input-group-text {
        background: transparent;
        border: none;
        height: 100%;
        height: 33px;
      }

      .form-control {
        border: transparent;
        text-align: center;
        height: 33px;
      }
    }
  }

  a {
    letter-spacing: 0.26em;
    text-decoration: none;
    color: #0063c5;
  }

  h4 {
    text-align: center;
    width: 44%;
    color: #c1c1c1;
    font-size: 15px;
    font-weight: 700;
    padding: 18px 0 25px;
  }

  .mintbtn:disabled {
    height: 100%;
    max-width: 135px;
    width: 100%;
    border-radius: 10px;
    opacity: 0.6;
  }
}