@import "../../../assets/scss/_mixins.scss";

.count {
  .timer {
    display: flex;

    @media (max-width: 560px) {
      flex-direction: column;
      align-items: center;
    }

    .timerSec {
      position: relative;
      margin-top: 10px;

      .edit_timers {
        position: absolute;
        top: -5px;
        right: -10px;
      }
    }
  }

  .labeltext {
    white-space: nowrap;
    color: #777777;
    letter-spacing: 0.26em;
    font-size: 15px;
    font-weight: 700;
    margin-top: -15px;
    margin-bottom: 0;

    @include breakpoint(large) {
      font-size: 14px;
      letter-spacing: 0.2em;
    }

    @include breakpoint(medium) {
      font-size: 13px;
    }

    @media (max-width: 560px) {
      margin-top: 0px;
    }
  }

  .timerSec {
    max-width: 200px;
    position: relative;
    width: 100%;

    .edit_timers {
      position: absolute;
      top: -5px;
      right: -10px;
    }

    .time {
      border: 1px solid #0000;
      border-radius: 10px;
      background: linear-gradient(#ffffff, #ffffff) padding-box,
        linear-gradient(120deg,
          rgba($color: #5C56F2, $alpha: 0.5),
          rgba($color: #6150EC, $alpha: 0.5)) border-box;
      padding: 6px 20px;
      position: relative;
      display: flex;
      align-items: center;

      span {
        border-right: 1px solid #ededed;
        padding: 0 13px;
        font-family: "Montserrat";
        font-style: italic;
        font-weight: 400;
        font-size: 15px;
        line-height: 132.1%;

        letter-spacing: -0.04em;

        color: #c1c1c1;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          border-right: none;
          padding-right: 0;
        }
      }
    }

    .tracker {
      font-weight: 400;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 2px 18px;
      font-family: "Montserrat";
      font-style: normal;
      font-size: 12px;
      line-height: 132.1%;
      letter-spacing: 0.025em;
      color: #c1c1c1;
    }
  }
}

.edit_timer_pencil {
  color: #c1c1c1;
  font-size: 13px;
}