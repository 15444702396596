.notification-box {
    display: flex;
    background:rgba(0, 0, 0, 0.8);
    justify-content: space-between!important;
    padding:20px;
    position:absolute;
    width:100%;
    z-index:22;
  
    div {
      color:#fff;
    }
  }