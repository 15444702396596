@import "../../../assets/scss/_var.scss";

.square_sec {
    .labeltext {
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
    }

    .inputSec {
        border-radius: 3px;
    }

    .align_timer {
        text-align: left;

        .timer {
            justify-content: flex-start !important;
        }
    }
}