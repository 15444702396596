@import "../../../assets/scss/_var.scss";

.lock_image {
    background-color: transparent;
    border: none;

    &:hover,
    &:focus,
    &:active {
        background-color: transparent;
        border: none;
        box-shadow: unset !important;
        outline: unset;
    }
}