@import "../../../assets/scss/_var.scss";
@import "../../../assets/scss/_mixins.scss";

.collection-basic-info {
  margin-bottom: 84px;

  @include breakpoint(large) {
    margin-bottom: 55px;
  }

  .Right {
    position: relative;
    // padding-left: 3%;

    .edit-collection {
      position: absolute;
      cursor: pointer;
      top: 12px;
      right: -25px;
    }

    .imgSec {
      width: 100%;
      // max-width: 503px;
      // height: 486px;
      height: 100%;
      background-color: $white;
      border: none;
      border-radius: 16px;
      filter: brightness(40%);

    }
  }
}

.nftImage {
  width: 100%;
  border: solid 10px white;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  max-width: 304px;
  border-radius: 50%;
  height: 291px;

  @media (max-width: 991px) {
    max-width: 250px;
    height: 250px;
  }

  @media (max-width: 575px) {
    max-width: 150px;
    height: 150px;
  }
}

.nftImage.withbanner {
  position: absolute;
  // top: 11%;
  // right: 41%;
  bottom: -130px;
  left: 0;
  right: 0;
  margin: 0 auto;

  @media (max-width: 991px) {
    bottom: -80px;
  }
}

.InputTitle {
  letter-spacing: 0.26em;
  white-space: nowrap;
  color: $textColorNew;
}

.inputSec {
  position: relative;
  font-size: 13px;
  color: rgba($color: #000000, $alpha: 1);
  border: 1px solid #0000;
  border-radius: 10px;
  background: linear-gradient(#ffffff, #ffffff) padding-box,
    linear-gradient(120deg,
      rgba($color: $purpleclr, $alpha: 0.5),
      rgba($color: $purpletext, $alpha: 0.5)) border-box;
  line-height: 42px;
  height: 100%;
  padding: 0 15px;
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  letter-spacing: -0.04em;
  color: #c1c1c1;

  @media (max-width: 560px) {
    margin-top: 10px;
    margin-left: 0;
  }
}

.width60 {
  max-width: 60px;
  width: 100%;
}

.width120 {
  max-width: 120px;
  width: 100%;
}

.width415 {
  max-width: 415px;
  width: 100%;
}

.frame {
  background-color: transparent;
  color: $purpleclr;
  border-color: $purpleclr;
  margin-top: 35px;
  font-style: italic;
  font-weight: 500;
  font-size: 15px;
  line-height: 132.1%;

  &:hover,
  &:active,
  &:focus {
    background-color: $purpleclr;
    border-color: rgba($color: $purpleclr, $alpha: 0.5);
  }

  /* identical to box height, or 20px */
  letter-spacing: 0.16em;

  color: $purpleclr;

  @include breakpoint(medium) {
    margin-bottom: 30px;
  }

  span {
    border-right: solid 1px $purpleclr;
    height: 16px;
    margin-right: 8px;
  }
}

.labeltext {
  font-family: "Montserrat";
  white-space: nowrap;
  color: #777777;
  letter-spacing: 0.26em;
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 0;
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include breakpoint(large) {
    font-size: 14px;
    letter-spacing: 0.2em;
  }

  @include breakpoint(medium) {
    font-size: 13px;
  }

  @media (max-width: 560px) {
    flex-direction: column;
    margin-bottom: 10px;
  }
}


.frame_sec {
  position: relative;

  .frame.square {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: $white;
    border: none;
    border-radius: 16px;
    height: 500px;
    width: 523px;
    text-align: center;
    padding: 10px;
    // max-width: 100%;
  }

  .upload_sec {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);

    .upload_btn {
      font-size: 16px;
      margin-bottom: 18px;
      box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
      background-color: transparent;
      border: 1px solid rgba(255, 255, 255, 1);
      padding: 11px 0;
      max-width: 186px;
      width: 100%;
      font-weight: 600;
    }

    .upload_text {
      font-size: 16px;
    }
  }
}