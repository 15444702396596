.imageUpload {
  background: linear-gradient(#ffffff, #ffffff) padding-box,
  linear-gradient(90deg, #5C56F2 0%, #821DB4 72.47%) border-box;
  border: solid 1px transparent;
  border-radius: 5px;
  text-align: center;
  max-width: 720px;
  height: 297px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 102px;
  cursor: pointer;

  img {
    margin-bottom: 15px;
  }
  input {
    display: none;
  }
  p {
    color: #B5B5B5;
  }
}

