@import "../../../../assets/scss/_var.scss";
@import "../../../../assets/scss/_mixins.scss";

.userLogPage {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  height: 100%;

  // .mainCard_style {
  //   border-top-left-radius: 0;
  //   border-top-right-radius: 0;
  // }
  .logBtn {
    border-top-left-radius: 20px;
  }

  .sgnBtn {
    // background: linear-gradient(90deg, #5C56F2 0%, #821DB4 72.47%) !important;
    background: linear-gradient(#5C56F2, #6150EC) padding-box,
      linear-gradient(#fff, #fff) border-box;
    border-top-right-radius: 20px;
    // border-top-left-radius: 20px;
  }

  .otp {
    border-top-left-radius: 20px !important;
  }

  .cmnSec {
    max-width: 522px;
    width: 100%;
    margin: 0 auto;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1) !important;

    @include breakpoint(xxlarge) {
      max-width: 450px;
    }
  }

  .topSec {
    display: flex;

    >a {
      text-align: center;
      padding: 9px 0;
      flex: 1;
      text-decoration: none;
      font-style: normal;
      font-weight: 500;
      font-size: 13.5px;
      line-height: 132.1%;
      letter-spacing: 0.085em;
      font-weight: normal;
      text-transform: capitalize;
      transition: all 0.3s ease-in-out;
      background: linear-gradient(#ffffff, #ffffff) padding-box,
        linear-gradient(90deg, #5C56F2 0%, #821DB4 72.47%);
      // color: rgba(255, 255, 255, 1);
      color: #821DB4;
      // background: linear-gradient(#ffffff, #ffffff) padding-box,
      //   linear-gradient(120deg, #42dace, #4786d6) border-box;
      font-weight: 500;
      letter-spacing: 0.085em;
      border: solid 1px transparent;

      @include breakpoint(xxlarge) {
        padding: 15px 0;
      }

      &.active {
        background: linear-gradient(90deg, #5C56F2 0%, #821DB4 72.47%);
        color: $white;
        // background: linear-gradient(260deg, #44dfd1 0%, #519bf4 100%);

        opacity: 1;
      }
    }
  }

  .loginSec.cmnSec {
    box-shadow: 0px 0px 14px #0000003b;

    .logForm {
      .fieldWrap {
        margin-bottom: 9px;

        input:focus+.formIcon {
          filter: invert(100%) sepia(1%) saturate(0%) hue-rotate(337deg) brightness(115%) contrast(100%);
        }


      }

      Form {
        margin-bottom: 0px;
      }
    }

    >div {
      position: relative;
      border-top-left-radius: 20px;

      .form-control {
        // background: linear-gradient(#ffffff, #ffffff) padding-box,
        //   linear-gradient(120deg,
        //     rgba(66, 218, 206, 0.5),
        //     rgba(71, 134, 214, 0.5)) border-box;
        @include transition(all ease 0.3s);
        // color: linear-gradient(90deg, #5C56F2 0%, #821DB4 72.47%);
        // border: 1px solid #821DB4;
        // background: transparent;
        background: linear-gradient(#ffffff, #ffffff) padding-box,
          linear-gradient(90deg, #5C56F2 0%, #821DB4 72.47%) border-box !important;
        color: #821DB4;
        border: solid 1px transparent;
        border-radius: 10px;
        -moz-border-radius: 10px;
        -webkit-border-radius: 10px;
        padding: 14px 18px;
        font-style: italic;
        font-weight: 300;
        font-size: 11px;
        line-height: 132.1%;
        letter-spacing: -0.025em;
        overflow: clip;

        @include breakpoint(xxlarge) {
          padding: 12px 12px 12px 40px;
        }

        &:focus,
        &:active,
        &:hover {
          background: linear-gradient(#ffffff, #ffffff) padding-box,
            linear-gradient(120deg,
              rgba(71, 134, 214, 0.5),
              rgba(66, 218, 206, 0.5)) border-box;
          @include transition(all ease 0.3s);
          box-shadow: unset;
          outline: unset;
        }

        &::placeholder {
          background: linear-gradient(#ffffff, #ffffff) padding-box,
            linear-gradient(90deg, #5C56F2 0%, #821DB4 72.47%);
          color: linear-gradient(90deg, #5C56F2 0%, #821DB4 72.47%);
        }
      }

      .formIcon {
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
      }
    }

    .cmnBtn1 {
      margin-bottom: 15px;
      margin-top: 40px;
    }

    .cmnBtn1,
    .cmnBtn2 {
      color: #fff;
      background: linear-gradient(90deg, #5C56F2 0%, #821DB4 72.47%);
      border: none;
      box-shadow: 4px 4px 7px #00000030;
      border-radius: 5px;
      margin: 22px auto 0;
      display: block;
      padding: 10px 35px;
      letter-spacing: 0.105em;

      @include breakpoint(small) {
        padding: 12px;
      }

      @include breakpoint(x-small) {
        padding: 10px;
      }

      @include breakpoint(xxlarge) {
        margin-top: 25px;
      }

      @include breakpoint(medium) {
        margin-top: 20px;
      }
    }
  }
}

#passwordHelp {
  text-decoration: none;
  cursor: pointer;
  display: inherit;
  margin-top: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 132.1%;
  letter-spacing: -0.01em;
  color: $purpleclr;
}

.signupBtn:disabled {
  opacity: 0.6 !important;
}