@import "../../../assets/scss/_var.scss";
@import "../../../assets/scss/_mixins.scss";
#select_cat{
    padding: 10px;
    
    
}


.col-padding {
    padding-top: 10px!important;
    align-items: center;

}

  
  .dropdown#select_cat {
    z-index: 10000;
    width: 300px;
    position: relative;
  }
  .dropdown#select_cat,
  .dropdown#select_cat * {
    z-index: 10;
  }
  .dropdown#select_cat .dropdown-btn {
    cursor: pointer;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    color: #777;
    font-weight: 500;
  }
  .dropdown-content {
    position: absolute;
    /* top: 110%; */
    left: 10px;
    background:#fff;
    width: calc(100% - 20px);

    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
  }
  .dropdown-content .item {
    padding: 10px;
    cursor: pointer;
  }
  .dropdown-content .item:hover {
    background: $purpleclr;
  }
  
  #select_cat button {
    z-index: -1;
    display: block;
    width: 300px;
    margin: 0 auto;
  }
  