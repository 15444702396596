.PrivateArea__content {
  height: 100%;
}
.PublicArea__content {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 767px) {
  .PublicArea__content {
    height: 100%;
  }
}
.PrivateArea__contentIn {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  // min-height: 950px;
  min-height: 100%;
  @media (max-width: 991px) {
    flex-direction: column;
    display: block;
  }
}
.dashLeft {
  width: 250px;
  box-shadow: 0px -4px 14px #0000001a;
  @media (max-width: 991px) {
    height: auto;
    background: transparent;
    // width: 100%;
    
  }
}
.dashRight {
  width: calc(100% - 250px);
 border-top: 1px solid #0dcaf02e;
  padding: 20px 12px 65px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  // @media (max-width: 1440px) {
  //   margin-top: 140px;
  // }
}
